import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { request } from "helper/http-client";
import { withMemo, dict } from "helper/global";
import { Popup } from "devextreme-react/popup";
import { Gallery } from "devextreme-react/gallery";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Button } from "devextreme-react/button";

function Component(props) {
  //
  // hooks
  const [state, setState] = useState(null);
  const [popup, setPopup] = useState(false);

  useEffect(
    () => {
      if (props.url === null) setState([]);
      (async () => {
        try {
          var result = await request({ url: props.url });
          setState(result);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.url, props.render]
  );

  // render

  function imageRender(data) {
    return (
      <img
        className={styles.img}
        src={data.url}
        alt={""}
        onDoubleClick={() => {
          setPopup(true);
        }}
      />
    );
  }

  function popUpImageRender(data) {
    return <img className={styles.popup_img} src={data.url} alt={""} />;
  }

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  function titleRender() {
    return (
      <div className={styles.button}>
        <Button
          hint={"Close"}
          icon={"close"}
          onClick={() => {
            setPopup(false);
          }}
        />
      </div>
    );
  }

  function galleryRender() {
    return (
      <div className={styles.main}>
        <Popup
          visible={popup}
          onHiding={() => setPopup(false)}
          dragEnabled={true}
          hideOnOutsideClick={false}
          showTitle={true}
          title={props.title}
          titleRender={titleRender}
          width={1000}
          height={1000}
        >
          <div className={styles.popup}>
            <Gallery
              dataSource={state}
              noDataText={"No image to display"}
              loop={false}
              showNavButtons={true}
              showIndicator={false}
              itemRender={popUpImageRender}
            />
          </div>
        </Popup>
        <Gallery
          dataSource={state}
          noDataText={dict("No image to display")}
          loop={false}
          showNavButtons={true}
          showIndicator={false}
          itemRender={imageRender}
        />
      </div>
    );
  }

  return state ? galleryRender() : loadingRender();
}

export default withMemo(Component, ["url", "render"]);

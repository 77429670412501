import React, { useContext, useMemo } from "react";
import HierarchyContext from "components/hierarchy/context";
import { url } from "config.js";
import { request } from "helper/http-client";
import CustomStore from "devextreme/data/custom_store";
import AssetList from "components/asset-grid/asset-grid";

function Component(props) {
  //
  // hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;

  // store

  const store = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/qaqc/getassetsbycostoverride`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
          },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  return useMemo(() => {
    return <AssetList title={`Assets with Cost Override`} dataSource={store} onBackButtonClick={props.onBackButtonClick} />;
  }, [selectedRowsData]);
}

export default Component;

import React, { useState, useRef } from "react";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import styles from "./document.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import TileView from "components/doc-tileview/doc-tileview";
import { dict, getIcon } from "helper/global";
import { withMemo } from "helper/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Component(props) {
  // useState

  const [render, setRender] = useState(0);

  // event handlers

  function onRefresh() {
    setRender((render) => render + 1);
  }

  return (
    <div className={styles.main}>
      <TileView
        id={props.assetId}
        urls={{
          read: `${url}/assetdocumentLog/assetLog/${props.id}`,
        }}
        //title={props.assetDescription}
        showToolbar={false}
        render={render}
        refresh={onRefresh}
        allowEditing={false}
      ></TileView>
    </div>
  );
}

export default withMemo(Component, ["id"]);

import React, { useRef } from "react";
import styles from "./select-box.module.scss";
import { SelectBox } from "devextreme-react/select-box";
import { produce } from "immer";

function Component(props) {
  //
  // hooks

  let isAiBased = useRef(0);
  let isNonAiBased = useRef(0);
  let selectBoxRef = useRef(null);

  // event handlers

  const onValueChanged = (e) => {
    props.cell.setValue(e.value);
    props.onSelectBoxDisplayValueChanged && props.onSelectBoxDisplayValueChanged(props.name, selectBoxRef.current.option("displayValue"));
  };

  // render

  const renderItem = (data) => {
    if (data.isAiSearch) {
      isAiBased.current++;
    } else {
      isNonAiBased.current++;
    }
    return (
      <div>
        {isAiBased.current > 0 && isNonAiBased.current === 1 && <div className={styles.item_line} />}
        <div className={styles.item} style={isAiBased.current > 0 && isNonAiBased.current === 1 ? { height: "45px" } : {}}>
          <div
            className={styles.item_text}
            style={isAiBased.current > 0 && isNonAiBased.current === 1 ? { marginTop: "25px" } : { marginTop: "0px" }}
          >
            {data.name}
          </div>
          {/* {data.isAiBased && (
            <div className={styles.item_icon}>
              <FontAwesomeIcon icon={faMicrochipAi} />
            </div>
          )} */}
          {data.isAiSearch && <div className={styles.item_icon}>AI</div>}
        </div>
      </div>
    );
  };

  return (
    <SelectBox
      defaultValue={props.cell.value}
      {...props.cell.column.lookup}
      itemRender={renderItem}
      onOpened={() => {
        isAiBased.current = 0;
        isNonAiBased.current = 0;
      }}
      searchEnabled={true}
      onInitialized={(e) => (selectBoxRef.current = e.component)}
      onValueChanged={onValueChanged}
    >
      {/* <Button name="button1" location={"after"} options={dropDownButton} /> */}
      {/* <Button name="button2" location={"after"} options={searchButton} /> */}
    </SelectBox>
  );
}

export default Component;

import React, { useState, useRef } from "react";
import styles from "./file.module.scss";
import { url } from "config.js";
import TileView from "components/doc-tileview/doc-tileview";
import { withMemo } from "helper/global";

function Component(props) {
  // useState
  const [render, setRender] = useState(0);

  // event handlers

  function onRefresh() {
    setRender((render) => render + 1);
  }

  return (
    <div className={styles.main}>
      <TileView
        id={props.assetId}
        urls={{ read: `${url}/assetfilelog/assetlog/${props.id}` }}
        title={props.assetDescription}
        render={render}
        refresh={onRefresh}
        allowEditing={false}
      ></TileView>
    </div>
  );
}

export default withMemo(Component, ["id"]);

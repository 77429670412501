import React, { useState } from "react";
import styles from "./popup.module.scss";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { withMemo, currency } from "helper/global";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import Measurement from "../measurement/measurement";
import Image from "../image/image";
import Document from "../document/document";
import File from "../file/file";
import Field from "../field/field";

function Compoment(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handler

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  function measurementRender() {
    return state.id && <Measurement id={state.id} />;
  }

  function imageRender() {
    return state.id && <Image id={state.id} />;
  }

  function documentRender() {
    return state.id && <Document id={state.id} />;
  }

  function fileRender() {
    return state.id && <File id={state.id} />;
  }

  function fieldRender() {
    return state.id && <Field id={state.id} />;
  }

  function tabsRender() {
    return (
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title={"Measurement"} render={measurementRender} />
        <Item title={"Image"} render={imageRender} />
        <Item title={"Document"} render={documentRender} />
        <Item title={"File"} render={fileRender} />
        <Item title={"Field"} render={fieldRender} />
      </TabPanel>
    );
  }
  return (
    <div className={styles.main}>
      <div className={styles.fullgrid}>
        <AssetLog scenarioNodeId={props.scenarioNodeId} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.tabs}>{state.id && tabsRender()}</div>
    </div>
  );
}

const AssetLog = withMemo((props) => {
  //
  // store

  const assetLogStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.scenarioNodeId) return Promise.resolve({ data: [] });
        return request({
          url: `${url}/assetLog/byscenarionode`,
          loadOptions: loadOptions,
          params: { scenarioNodeId: props.scenarioNodeId },
        });
      },
    }),
  };

  // render

  return (
    <Grid
      title={"Asset Log"}
      showTitle={false}
      selection={{ mode: "single" }}
      dataSource={assetLogStore}
      onSelectionChanged={props.onSelectionChanged}
      allowExpanding={false}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
    >
      <Column caption={"Id"} visible={false} dataField={"id"} width={100} />
      <Column
        caption={"ReplacedOn"}
        dataField={"replacedOn"}
        dataType={"date"}
        dateSerializationFormat={"yyyy-MM-dd"}
        format="MM/dd/yyyy"
        width={150}
        sortOrder={"desc"}
      />
      <Column caption={"Uid"} dataField={"uid"} width={400} />
      <Column caption={"InstallYear"} dataField={"installYear"} dataType={"number"} width={150} />
      <Column caption={"Class"} dataField={"class"} allowEditing={false} width={200} />
      <Column caption={"Type"} dataField={"type"} allowEditing={false} width={200} />
      <Column caption={"Size"} dataField={"size"} allowEditing={false} width={200} />
      <Column caption={"Material"} dataField={"material"} allowEditing={false} width={200} />
      <Column caption={"Manufacturer"} dataField={"manufacturer"} allowEditing={false} width={200} />
      <Column caption={"Model"} dataField={"model"} dataType={"string"} allowEditing={false} width={200} />
      <Column caption={"Count"} dataField={"count"} allowEditing={false} width={200} />
      <Column caption={"Cost"} dataField={"cost"} allowEditing={false} width={200} dataType="number" format={`${currency()}#,##0.##`} />
      {/* <Column caption={"Value"} dataField={"value"} allowEditing={false} width={200} /> */}
    </Grid>
  );
}, []);

export default Compoment;

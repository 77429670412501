import React, { useState } from "react";
import styles from "./image.module.scss";
import TileView from "components/tileview/tileview";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { withMemo } from "helper/global";

function Component(props) {
  const [render, setRender] = useState(0);

  // event handlers

  function onRefresh() {
    setRender((render) => render + 1);
  }

  return (
    <div className={styles.main}>
      <TileView
        id={props.assetId}
        urls={{
          read: `${url}/assetimagelog/assetlog/${props.id}`,
        }}
        // title={props.data.description}
        allowEditing={false}
        render={render}
        refresh={onRefresh}
      ></TileView>
    </div>
  );
}

export default withMemo(Component, ["id"]);

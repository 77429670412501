import React, { useMemo } from "react";
import { url } from "config.js";
import { request } from "helper/http-client";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid.js";
import { Column } from "devextreme-react/data-grid";

function Component(props) {
  // store

  const store = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/qaqc/getassetcountbystrategy`,
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      visible: !!props.onBackButtonClick,
      options: {
        hint: "Back",
        type: "back",
        onClick: props.onBackButtonClick,
      },
    });
    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  return useMemo(() => {
    return (
      <Grid
        title={"Strategy Asset Count"}
        showTitle={true}
        dataSource={store}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowSoring={false}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Column visible={false} dataField={"id"} allowSoring={false} />
        <Column caption={"Name"} dataField={"name"} allowSoring={false} width={400}></Column>
        <Column caption={"Count"} dataField={"count"} width={150} allowSoring={false} dataType="number" format={`#,##0.##`} />
      </Grid>
    );
  }, []);
}

export default Component;

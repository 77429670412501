import React, { useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import { currency } from "helper/global";
import { Column } from "devextreme-react/data-grid";
import AssetEditor from "components/asset-editor/asset-editor";
import MultiEditor from "components/multi-editor/multi-editor";
import { useImmerReducer } from "use-immer";
import { connect } from "react-redux";

function reducer(draft, action) {
  switch (action.type) {
    case "showAssetEditor":
      draft.showAssetEditor = true;
      draft.nodeId = action.payload.nodeId;
      return;
    case "hideAssetEditor":
      draft.showAssetEditor = false;
      return;
    case "showMultiEditor":
      draft.showMultiEditor = true;
      return;
    case "hideMultiEditor":
      draft.showMultiEditor = false;
      return;
    default:
      return;
  }
}

function Component(props) {
  //
  // hooks
  const initialState = {
    nodeId: null,
    showAssetEditor: false,
    showMultiEditor: false,
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const dataGrid = useRef(null);

  // event handlers

  function hideAssetEditor(e) {
    dispatch({ type: "hideAssetEditor" });
  }

  function hideMultiEditor(e) {
    dispatch({ type: "hideMultiEditor" });
  }

  function refresh() {
    dataGrid.current.refresh();
  }

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  function onAssetEditor(e) {
    dispatch({ type: "showAssetEditor", payload: { nodeId: e.row.data.id, assetId: e.row.data.assetId } });
  }

  function onMultiEditor(e) {
    dispatch({ type: "showMultiEditor" });
  }

  // render

  return props.multiEditOptions ? (
    <React.Fragment>
      <AssetEditor nodeId={state.nodeId} refresh={refresh} visible={state.showAssetEditor} hideEditor={hideAssetEditor} />
      <MultiEditor options={props.multiEditOptions} visible={state.showMultiEditor} onHiding={hideMultiEditor}>
        <DataGrid
          title={props.title}
          dataSource={props.dataSource}
          multiEditOptions={props.multiEditOptions}
          onInitialized={onInitialized}
          onBackButtonClick={props.onBackButtonClick}
          onLoading={props.onLoading}
          onLoaded={props.edittor}
          onMultiEditor={onMultiEditor}
          onAssetEditor={onAssetEditor}
          multiEditUrl={props.multiEditUrl}
          onToolbarPreparing={props.onToolbarPreparing}
          showActivity={props.showActivity}
        />
      </MultiEditor>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <AssetEditor nodeId={state.nodeId} refresh={refresh} visible={state.showAssetEditor} hideEditor={hideAssetEditor} />
      <DataGrid
        title={props.title}
        dataSource={props.dataSource}
        multiEditOptions={props.multiEditOptions}
        onInitialized={onInitialized}
        onBackButtonClick={props.onBackButtonClick}
        onLoading={props.onLoading}
        onLoaded={props.edittor}
        onMultiEditor={onMultiEditor}
        onAssetEditor={onAssetEditor}
        multiEditUrl={props.multiEditUrl}
        onToolbarPreparing={props.onToolbarPreparing}
        showActivity={props.showActivity}
      />
    </React.Fragment>
  );
}

export default Component;

function mapStateToProps(state) {
  return {
    multiEditNode: !!state.user.accessAdmin,
  };
}

const DataGrid = connect(
  mapStateToProps,
  null
)(
  React.memo(
    (props) => {
      // render

      function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
          {
            location: "before",
            widget: "dxButton",
            visible: !!props.onBackButtonClick,
            options: {
              hint: "Back",
              type: "back",
              onClick: props.onBackButtonClick,
            },
          },
          {
            location: "after",
            widget: "dxButton",
            visible: props.multiEditNode,
            options: {
              hint: "MultiEdit",
              visible: !!props.multiEditOptions,
              icon: "fas fa-edit",
              onClick: props.onMultiEditor,
            },
          }
        );
        props.onToolbarPreparing && props.onToolbarPreparing(e);
      }

      return (
        <Grid
          title={props.title}
          showTitle={props.showTitle}
          dataSource={props.dataSource}
          allowAdding={false}
          allowUpdating={false}
          allowDeleting={false}
          onToolbarPreparing={onToolbarPreparing}
          onInitialized={props.onInitialized}
        >
          <Column visible={false} dataField={"id"} />
          <Column caption={"AssetId"} dataField={"name"} width={200} />
          <Column caption={"Description"} dataField={"description"} width={400} />
          <Column caption={"Activity Name"} dataField={"activityName"} width={200} visible={!!props.showActivity} />
          <Column caption={"Activity Description"} dataField={"activityDescription"} width={200} visible={!!props.showActivity} />
          <Column caption={"Activity Type"} dataField={"activityType"} width={200} visible={!!props.showActivity} />
          <Column caption={"Location"} dataField={"location"} width={200} />
          <Column caption={"Ownership"} dataField={"ownership"} width={150} />
          <Column caption={"Status"} dataField={"status"} width={150} />
          <Column caption={"Class"} dataField={"class"} width={150} />
          <Column caption={"Type"} dataField={"type"} width={150} />
          <Column caption={"Size"} dataField={"size"} width={150} />
          <Column caption={"Material"} dataField={"material"} width={150} />
          <Column caption={"Manufacturer"} dataField={"manufacturer"} width={150} />
          <Column caption={"Model"} dataField={"model"} width={150} />
          <Column caption={"Count"} dataField={"count"} dataType="number" width={150} />
          <Column caption={"Install Year"} dataType="number" dataField={"installYear"} width={150} />
          <Column caption={"Useful Life"} dataField={"usefulLife"} dataType="number" width={150} />
          <Column caption={"StrategyId"} dataField={"strategy"} width={150} />
          <Column caption={"Replacement / Program Cost"} dataField={"activityCost"} width={150} format={`${currency()}#,###`} />
          <Column caption={"Consumed"} dataField={"consumed"} dataType="number" width={150} format={"0.##"} />
          <Column caption={"Condition"} dataField={"condition"} dataType="number" width={150} format={"0.##"} />
          <Column caption={"R"} dataField={"r"} dataType="number" width={150} format={"0.##"} />
          <Column caption={"Standby"} dataField={"standby"} dataType="number" width={150} />
          <Column caption={"PoF"} dataField={"pof"} dataType="number" width={150} format={"#0.00"} />
          <Column caption={"CoF"} dataField={"cof"} dataType="number" width={150} format={"#0.00"} />
          <Column caption={"Bre"} dataField={"bre"} dataType="number" width={150} format={"#0.##"} />
          <Column caption={"Condition Rating"} dataField={"conditionRating"} width={150} />
          <Column caption={"Risk Rating"} dataField={"riskRating"} width={150} />
          <Column
            type={"buttons"}
            buttons={[
              "edit",
              {
                hint: "Edit",
                icon: "edit",
                visible: true,
                onClick: props.onAssetEditor,
              },
            ]}
            width={125}
            fixed={true}
          />
        </Grid>
      );
    },
    (prev, next) => {
      return prev.dataSource === next.dataSource;
    }
  )
);

import React, { useEffect, useRef } from "react";
import TileView from "devextreme-react/tile-view";
import Toolbar, { Item } from "devextreme-react/toolbar";
import styles from "./tileview.module.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./tileview.scss";
import FileUploader from "components/blob-uploader/blob-uploader";
import { request } from "helper/http-client";
import { notify } from "helper/global";
import { Popup } from "devextreme-react/popup";
import { Gallery } from "devextreme-react/gallery";
import { confirm } from "devextreme/ui/dialog";
import { useImmerReducer } from "use-immer";

function reducer(draft, action) {
  switch (action.type) {
    case "showAdd":
      draft.add = true;
      return;
    case "hideAdd":
      draft.data = null;
      draft.add = false;
      draft.selected = null;
      draft.render = draft.render + 1;
      return;
    case "showGallery":
      draft.gallery = true;
      return;
    case "hideGallery":
      draft.gallery = false;
      return;
    case "select":
      draft.selected = action.selected;
      return;
    case "delete":
      draft.data = null;
      draft.selected = null;
      draft.render = draft.render + 1;
      return;
    case "data":
      draft.data = action.data;
      return;
    case "render":
      draft.render = draft.render + 1;
      return;
    default:
      return;
  }
}

const initialState = {
  data: null,
  render: 0,
  upload: false,
  add: false,
  gallery: false,
  selected: null,
};

function Component(props) {
  //
  // hooks

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  const selectedId = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        var result = await request({ url: props.urls.read });
        dispatch({ type: "data", data: result });
      } catch (error) {
        notify(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.urls, props.render, state.render]);

  // const

  const allowEditing = props.allowEditing === undefined || props.allowEditing;

  // event handlers

  function onAdd() {
    dispatch({ type: "showAdd" });
  }

  function hideAdd() {
    dispatch({ type: "hideAdd" });
  }

  function onItemClick(e) {
    selectedId.current = e.itemData.id;
    dispatch({ type: "select", selected: e.itemIndex });
  }

  function onHiding(e) {
    dispatch({ type: "hideGallery" });
  }

  function onSelectionChanged(e) {
    dispatch({ type: "select", selected: null });
  }

  async function onDelete() {
    if (selectedId.current) {
      var result = await confirm("<i>Do you really want to delete the selected image?</i>", "Confirm");
      if (result) {
        try {
          await request({ url: `${props.urls.delete}/${selectedId.current}`, method: "Delete" });
          dispatch({ type: "delete" });
          props.refresh && props.refresh();
        } catch (error) {
          notify(error, "error");
        }
      }
    }
  }

  function onUploaded() {
    hideAdd();
    props.refresh && props.refresh();
  }

  function onDoubleClick() {
    dispatch({ type: "showGallery" });
  }

  function tileRender(data) {
    return <img className={styles.img} onDoubleClick={onDoubleClick} src={data.url} alt={""} />;
  }

  function loadIndicatorRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }
  function toolbarRender() {
    return (
      <div className={`components_tileview`}>
        <Toolbar>
          <Item location="before" widget="dxButton" options={{ icon: "fas fa-plus", onClick: onAdd }} />
          <Item location="before" widget="dxButton" options={{ icon: "fas fa-minus", onClick: onDelete }} />
        </Toolbar>
      </div>
    );
  }

  function tileViewRender() {
    return (
      // <div className={`${styles.tileview}`}>
      <TileView height={"100%"} direction="vertical" items={state.data} itemRender={tileRender} onItemClick={onItemClick}></TileView>
      // </div>
    );
  }

  function popUpImageRender(data) {
    return <img className={styles.popup_img} src={data.url} alt={""} />;
  }

  function popupRender() {
    return (
      <Popup
        className={"gallery"}
        visible={state.gallery}
        onHiding={onHiding}
        dragEnabled={true}
        showCloseButton={true}
        showTitle={true}
        title={props.title}
        width={1000}
        height={1000}
      >
        <div className={styles.popup_gallery}>
          <Gallery
            dataSource={state.data}
            loop={false}
            showNavButtons={true}
            selectedIndex={state.selected}
            showIndicator={false}
            onSelectionChanged={onSelectionChanged}
            itemRender={popUpImageRender}
          />
        </div>
      </Popup>
    );
  }

  return (
    <div className={styles.main}>
      <FileUploader
        multiple={true}
        allowedFileExtensions={[".jpg", ".jpeg", ".gif", ".png"]}
        fileLoaderVisible={state.add}
        hideFileLoader={hideAdd}
        uploadUrl={props.urls.upload}
        onUploaded={onUploaded}
      />
      {allowEditing && toolbarRender()}
      {state.data ? tileViewRender() : loadIndicatorRender()}
      {state.data ? popupRender() : null}
    </div>
  );
}

export default Component;

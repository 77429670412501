import React from "react";
import styles from "./card.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Card(props) {
  function onClick() {
    props.onClick && props.onClick({ name: props.name });
  }

  const shade = {
    //backgroundColor: "rgba(#fff, 0.2)",
    color: props.color,
  };

  const line = {
    backgroundColor: props.color,
  };

  return (
    <div className={`dx-card ${styles.main} ${props.cursor && styles.main_cursor}`} onClick={onClick}>
      <div className={styles.grid}>
        <div className={styles.line} style={line}></div>
        <div className={styles.text}>
          <div className={styles.title}>{props.title || "No Title"}</div>
        </div>
        <div className={styles.icon} style={shade}>
          <FontAwesomeIcon icon={props.icon} />
        </div>
      </div>
    </div>
  );
}

export default Card;

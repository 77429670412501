import React, { useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // useRef

  const exporting = useRef(false);

  // stores

  const fieldStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.id) return Promise.resolve({ data: [] });
        return request({ url: `${url}/assetcustomfieldvaluelog/byassetlogid`, loadOptions: loadOptions, params: { assetLogId: props.id } });
      },
    }),
  };

  // render

  return (
    <Grid
      title={"Field"}
      showTitle={false}
      dataSource={fieldStore}
      selection={{ mode: "single" }}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowExpanding={true}
      allowDownloading={false}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column visible={false} caption={"AssetId"} dataField={"node"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"field"} allowFiltering={true} allowEditing={false} width={200} formItem={{ readOnly: true }} />
      <Column caption={"Unit"} dataField={"unit"} allowFiltering={true} width={200} allowEditing={false} formItem={{ readOnly: true }} />
      <Column caption={"Value"} dataField={"value"} allowFiltering={true} width={200}></Column>
    </Grid>
  );
}

export default withMemo(Component, ["id"]);

import React, { useState } from "react";
import styles from "./assetLog.module.scss";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import Grid from "components/data-grid/data-grid";
import ImageBox from "components/image-box/image-box";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { withMemo, currency } from "helper/global";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import PopupGrid from "../assetLog/popup/popup";

function Component(props) {
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);
  const [expand, setExpand] = useState(false);

  // event handler

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  function onExpand() {
    setExpand(true);
  }

  //render

  function titleRender() {
    return (
      <div className={styles.header}>
        <div className={styles.title}>Asset Log</div>
        <div className={styles.button}>
          <Button
            hint={"Close"}
            icon={"close"}
            onClick={() => {
              setExpand(false);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {!expand && (
        <div className={styles.compactgrid}>
          <CompactGrid id={props.id} render={props.render} onExpand={onExpand} onSelectionChanged={onSelectionChanged} />
        </div>
      )}
      {expand && (
        <Popup
          visible={expand}
          onHiding={() => {
            setExpand(false);
          }}
          showCloseButton={true}
          dragEnabled={true}
          hideOnOutsideClick={false}
          showTitle={true}
          width={1080}
          height={900}
          titleRender={titleRender}
        >
          <PopupGrid scenarioNodeId={props.id} />
        </Popup>
      )}
      <div className={styles.gallery}>
        {state.id && <ImageBox url={`${url}/assetimagelog/assetlog/${state.id}`} render={props.render} />}
      </div>
    </div>
  );
}

// export default withMemo(Component, ["id", "render"]);
export default Component;

const CompactGrid = withMemo(
  (props) => {
    //
    // store

    const assetLogStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          if (!props.id) return Promise.resolve({ data: [] });
          return request({
            url: `${url}/assetLog/byscenarionode`,
            loadOptions: loadOptions,
            params: { scenarioNodeId: props.id },
          });
        },
      }),
    };
    // render

    function onToolbarPreparing(e) {
      e.toolbarOptions.items.push({
        location: "before",
        widget: "dxButton",

        options: {
          hint: "Expand",
          icon: "fas fa-expand-alt",
          onClick: () => {
            props.onExpand();
          },
        },
      });
    }

    return (
      <Grid
        title={"AssetLog"}
        showTitle={false}
        selection={{ mode: "single" }}
        dataSource={assetLogStore}
        onSelectionChanged={props.onSelectionChanged}
        allowExpanding={false}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowDownloading={false}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Column caption={"Id"} visible={false} dataField={"id"} width={100} />
        <Column
          caption={"ReplacedOn"}
          dataField={"replacedOn"}
          dataType={"date"}
          dateSerializationFormat={"yyyy-MM-dd"}
          format="MM/dd/yyyy"
          width={150}
          sortOrder={"desc"}
        />
        <Column caption={"Uid"} dataField={"uid"} width={400} />
        <Column caption={"InstallYear"} dataField={"installYear"} dataType={"number"} width={150} />
        <Column caption={"Cost"} dataField={"cost"} allowEditing={false} width={200} dataType="number" format={`${currency()}#,##0.##`} />
      </Grid>
    );
  },
  ["id", "expand", "render"]
);

import React, { useContext, useMemo } from "react";
import HierarchyContext from "components/hierarchy/context";
import { url } from "config.js";
import { request } from "helper/http-client";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid.js";
import { Column } from "devextreme-react/data-grid";

function Component(props) {
  //
  // hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;

  // store

  const store = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/qaqc/getassetcountbyfolder`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
          },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      visible: !!props.onBackButtonClick,
      options: {
        hint: "Back",
        type: "back",
        onClick: props.onBackButtonClick,
      },
    });
    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  return useMemo(() => {
    return (
      <Grid
        title={"Folder Asset Count"}
        showTitle={true}
        dataSource={store}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Column visible={false} dataField={"id"} allowSoring={false} />
        <Column caption={"Name"} dataField={"name"} allowSorting={false} width={400}></Column>
        <Column caption={"Description"} dataField={"description"} allowSorting={false} width={400} />
        <Column caption={"Count"} dataField={"count"} width={150} allowSorting={false} dataType="number" format={`#,##0.##`} />
      </Grid>
    );
  }, [selectedRowsData]);
}

export default Component;

import React, { useContext, useMemo } from "react";
import styles from "./dashboard.module.scss";
import Hierarchy from "components/hierarchy/hierarchy";
import { url } from "config.js";
import { withMemo } from "helper/global";
import { useImmerReducer } from "use-immer";
import Status from "./status/status";
import Ownership from "./ownership/ownership";
import NodeType from "./nodetype/nodetype";
import Calculation from "./calculation/calculation";
import Cost from "./cost/cost";
import Strategy from "./strategy/strategy";
import { StatusAsset } from "./status/status";
import { OwnershipAsset } from "./ownership/ownership";
import { NodeTypeAsset } from "./nodetype/nodetype";
import { CalculationAsset } from "./calculation/calculation";
import { CostAsset } from "./cost/cost";
import { StrategyAsset } from "./strategy/strategy";
import FolderAssetCount from "./folder/folder";
import StrategyAssetCount from "./strategycount/strategycount";
import CostOverrideAsset from "./costoverride/costoverride";
import CofAsset from "./cof/cof";
import Card from "./card/card";
import { faFolders, faOverline, faGreaterThanEqual, faChessKnight } from "@fortawesome/pro-duotone-svg-icons";

function reducer(draft, action) {
  switch (action.type) {
    case "showGrid":
      draft.showGrid = true;
      draft.gridName = action.payload.name;
      draft.gridParam = action.payload.param;
      return;
    case "hideGrid":
      draft.showGrid = false;
      draft.gridName = null;
      draft.gridParam = null;
      return;
  }
}

function Component() {
  //
  // hooks

  const initialState = {
    showGrid: false,
    gridName: null,
    gridParam: null,
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  // event handlers

  function onShowGrid(e) {
    dispatch({ type: "showGrid", payload: e });
  }

  function onHideGrid() {
    dispatch({ type: "hideGrid" });
  }

  // render

  return (
    <div className={styles.main}>
      <Hierarchy
        addButton={false}
        allowEditing={false}
        allowDragging={false}
        multiSelect={true}
        showScenarioName={true}
        countUrl={`${url}/engine/output/node-count`}
        showFilter={false}
        showCount={true}
      >
        {state.showGrid ? (
          <Grid gridName={state.gridName} gridParam={state.gridParam} onHideGrid={onHideGrid} />
        ) : (
          <Dashboard onShowGrid={onShowGrid} />
        )}
      </Hierarchy>
    </div>
  );
}

export default Component;

const Dashboard = withMemo((props) => {
  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <div className={styles.card}>
          <Card
            title={"Folder Asset Count"}
            name={"folderCount"}
            cursor={true}
            icon={faFolders}
            color={"#955F71"}
            onClick={props.onShowGrid}
          />
        </div>
        <div className={styles.card}>
          <Card
            title={"Strategy Asset Count"}
            name={"strategyCount"}
            cursor={true}
            icon={faChessKnight}
            color={"#AF8A53"}
            onClick={props.onShowGrid}
          />
        </div>
        <div className={styles.card}>
          <Card
            title={"Assets Out of CoF Range"}
            name={"cof"}
            cursor={true}
            icon={faGreaterThanEqual}
            color={"#BA4D51"}
            onClick={props.onShowGrid}
          />
        </div>
        <div className={styles.card}>
          <Card
            title={"Assets with Cost Override"}
            name={"costoverride"}
            cursor={true}
            icon={faOverline}
            color={"#859666"}
            onClick={props.onShowGrid}
          />
        </div>
      </div>
      <div className={styles.item}>
        <Status onShowGrid={props.onShowGrid} />
      </div>
      <div className={styles.item}>
        <NodeType onShowGrid={props.onShowGrid} />
      </div>
      <div className={styles.item}>
        <Ownership onShowGrid={props.onShowGrid} />
      </div>
      <div className={styles.item}>
        <Calculation onShowGrid={props.onShowGrid} />
      </div>
      <div className={styles.item}>
        <Strategy onShowGrid={props.onShowGrid} />
      </div>
      <div className={styles.item}>
        <Cost onShowGrid={props.onShowGrid} />
      </div>
    </div>
  );
}, []);

const Grid = withMemo((props) => {
  //

  function gridRender() {
    switch (props.gridName) {
      case "status":
        return <StatusAsset status={props.gridParam} onBackButtonClick={props.onHideGrid} />;
      case "ownership":
        return <OwnershipAsset ownership={props.gridParam} onBackButtonClick={props.onHideGrid} />;
      case "nodetype":
        return <NodeTypeAsset nodetype={props.gridParam} onBackButtonClick={props.onHideGrid} />;
      case "calculationstatus":
        return <CalculationAsset calculationStatus={props.gridParam} onBackButtonClick={props.onHideGrid} />;
      case "cost":
        return <CostAsset costBasis={props.gridParam} onBackButtonClick={props.onHideGrid} />;
      case "strategy":
        return <StrategyAsset strategy={props.gridParam} onBackButtonClick={props.onHideGrid} />;
      case "folderCount":
        return <FolderAssetCount onBackButtonClick={props.onHideGrid} />;
      case "strategyCount":
        return <StrategyAssetCount onBackButtonClick={props.onHideGrid} />;
      case "costoverride":
        return <CostOverrideAsset onBackButtonClick={props.onHideGrid} />;
      case "cof":
        return <CofAsset onBackButtonClick={props.onHideGrid} />;
      default:
        return <></>;
    }
  }

  return gridRender();
}, []);

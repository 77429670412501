import React, { useState, useRef } from "react";
import styles from "./cost.module.scss";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import CostComponent from "../cost-component/cost-component";
import { currency, withMemo, addFilter } from "helper/global";

function Component(props) {
  // useState

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // useRef

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <ProgressPanel name={"UPLOAD_COST"}>
      <div className={styles.main}>
        <div className={styles.cost}>
          <Cost costGroupId={props.costGroupId} onSelectionChanged={onSelectionChanged} />
        </div>
        <div className={styles.container}>{<CostComponent costId={state.id} costGroupId={props.costGroupId} />}</div>
      </div>
    </ProgressPanel>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default Component;

const Cost = connect(
  null,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      //
      // store
      const costStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            if (!props.costGroupId) return Promise.resolve({ data: [] });
            return exporting.current
              ? request({ url: `${url}/cost/download`, loadOptions: loadOptions, params: { costGroupId: props.costGroupId } })
              : request({ url: `${url}/cost/bygroup`, loadOptions: loadOptions, params: { costGroupId: props.costGroupId } });
          },
          insert: (data) => {
            data.activityId = props.activityId;
            return request({ url: `${url}/cost`, method: "Post", data: data });
          },
          update: (_, data) => {
            data.activityId = props.activityId;
            return request({ url: `${url}/cost`, method: "Put", data: data });
          },
          remove: (id) => {
            return request({ url: `${url}/cost/${id}`, method: "Delete" });
          },
        }),
      };

      const measurementTypeStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/measurementtype`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/measurementtype/${id}` });
          },
        }),
      };

      const costGroupStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/costgroup`, loadOptions: addFilter(loadOptions, "and", ["internal", "=", false]) });
          },
          byKey: function (id) {
            return request({ url: `${url}/costgroup/${id}` });
          },
        }),
      };

      // const fundStore = {
      //   store: new CustomStore({
      //     key: "id",
      //     useDefaultSearch: true,
      //     load: (loadOptions) => {
      //       return request({ url: `${url}/fund`, loadOptions: loadOptions });
      //     },
      //     byKey: function (id) {
      //       return request({ url: `${url}/fund/${id}` });
      //     },
      //   }),
      // };

      const costCenterStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/costcenter`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/costcenter/${id}` });
          },
        }),
      };

      // hooks

      const exporting = useRef(false);

      // event handlers

      function onSelectionChanged(e) {
        props.onSelectionChanged && props.onSelectionChanged(e);
      }

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_COST", url: `${url}/cost/upload/file/${fileId}` });
      }

      function onExporting(e) {
        e.component.columnOption("id", "visible", true);
        e.component.columnOption("id", "sortOrder", "asc");
        e.component.columnOption("operation", "visible", true);
        e.component.columnOption("asset", "visible", true);
        e.component.columnOption("unit", "visible", false);
        e.component.columnOption("measurementTypeId", "visible", true);
        e.component.columnOption("groupId", "visible", true);
        exporting.current = true;
      }

      function onExported(e) {
        e.component.columnOption("id", "visible", false);
        e.component.columnOption("id", "sortOrder", "desc");
        e.component.columnOption("operation", "visible", false);
        e.component.columnOption("asset", "visible", false);
        e.component.columnOption("unit", "visible", true);
        e.component.columnOption("measurementTypeId", "visible", false);
        e.component.columnOption("groupId", "visible", false);
        exporting.current = false;
      }

      // render

      return (
        <Grid
          title={"Cost"}
          dataSource={costStore}
          selection={{ mode: "single" }}
          onUploaded={onUploaded}
          onSelectionChanged={onSelectionChanged}
          onExported={onExported}
          onExporting={onExporting}
          allowExpanding={true}
          onInitNewRow={(e) => {
            e.data.groupId = props.costGroupId || null;
          }}
        >
          <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            dataField={"operation"}
            calculateCellValue={() => "Update"}
            visible={false}
            formItem={{ visible: false }}
          />
          <Column caption={"Name"} dataField={"name"} width={200} />
          <Column caption={"Description"} dataField={"description"} width={400} />
          <Column
            caption={"Cost Override"}
            dataField={"costOverride"}
            dataType="number"
            width={100}
            format={`${currency()}#,##0.##`}
            formItem={{ editorOptions: { format: `${currency()}#,##0.##`, showClearButton: true } }}
          />
          <Column
            caption={"Measurement"}
            dataField={"measurementTypeId"}
            calculateDisplayValue="measurementType"
            allowFiltering={true}
            visible={true}
            width={200}
          >
            <Lookup dataSource={measurementTypeStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column caption={"Group"} dataField={"groupId"} visible={false} calculateDisplayValue="group" allowFiltering={true} width={150}>
            <Lookup dataSource={costGroupStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          {/* <Column caption={"Fund"} dataField={"fundId"} calculateDisplayValue="fund" width={200}>
            <Lookup dataSource={fundStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column> */}
          <Column caption={"Cost Center"} dataField={"costCenterId"} calculateDisplayValue="costCenter" width={200}>
            <Lookup dataSource={costCenterStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
          <Column
            caption={"Updated On"}
            dataField={"updatedOn"}
            dataType={"date"}
            dateSerializationFormat={"yyyy-MM-dd"}
            format="MM/dd/yyyy"
            width={150}
            formItem={{ editorOptions: { showClearButton: true } }}
          />
          <Column
            caption={"Update Notes"}
            dataField={"updateNotes"}
            width={400}
            // formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
          />
          <Column
            caption={"Notes"}
            dataField={"notes"}
            allowFiltering={true}
            formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
            width={400}
          />
          <Column visible={true} formItem={{ itemType: "empty" }} />
        </Grid>
      );
    },
    ["costGroupId"]
  )
);

import React, { useEffect, useContext, useMemo } from "react";
import styles from "./status.module.scss";
import HierarchyContext from "components/hierarchy/context";
import PieChart from "components/piechart/piechart";
import { Series, Label, Legend, Font, Margin, Border, AdaptiveLayout } from "devextreme-react/chart";
import { useImmerReducer } from "use-immer";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { url } from "config.js";
import { request } from "helper/http-client";
import { commaFormat, withMemo } from "helper/global";
import CustomStore from "devextreme/data/custom_store";
import AssetList from "components/asset-grid/asset-grid";

function reducer(draft, action) {
  switch (action.type) {
    case "data":
      draft.data = action.payload;
      draft.loading = false;
      return;
    case "loading":
      draft.loading = true;
      return;
  }
}

function Component(props) {
  //
  // hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;

  const initialState = {
    data: null,
    loading: false,
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "loading" });
    (async () => {
      const result = await request({
        url: `${url}/qaqc/getcountbystatus`,
        params: { ids: selectedRowsData.map((i) => i.id) },
      });
      dispatch({ type: "data", payload: result });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData]);

  //
  // event handlers

  function customizePoint(e) {
    return {
      color: e.data.color,
    };
  }

  function customizeText(e) {
    return commaFormat(e.value);
  }

  function onPointClick(e) {
    props.onShowGrid({ name: "status", param: e.target.originalArgument });
  }

  function onLegendClick(e) {
    props.onShowGrid({ name: "status", param: e.target });
  }

  // render

  function loadingRender() {
    return (
      <div className={styles.overlay}>
        <LoadIndicator id="image-indicator" />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {state.loading && loadingRender()}
      <div className={styles.title}>Status</div>
      <div className={styles.chart}>
        <PieChart
          key={"id"}
          dataSource={state.data}
          type="donut"
          innerRadius={0.65}
          palette={"Office"}
          customizePoint={customizePoint}
          onPointClick={onPointClick}
          onLegendClick={onLegendClick}
        >
          <Series argumentField="name" valueField="count">
            <Label format="fixedPoint" visible={true} position="inside" backgroundColor="none" customizeText={customizeText}>
              <Font size={11} />
            </Label>
          </Series>
          <Legend visible={true} orientation="vertical" verticalAlignment="center" horizontalAlignment="left">
            <Margin right={0} left={0} Top={0} bottom={0}></Margin>
            <Border visible={false}></Border>
          </Legend>
        </PieChart>
      </div>
      <div className={styles.note}>*Include ignored Assets</div>
    </div>
  );
}

export default Component;

export const StatusAsset = (props) => {
  //
  // hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;

  // store

  const store = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/qaqc/getassetsbystatus`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            status: props.status,
          },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  return useMemo(() => {
    return <AssetList title={`Status: ${props.status}`} dataSource={store} onBackButtonClick={props.onBackButtonClick} />;
  }, [selectedRowsData]);
};

import React, { useState, useEffect } from "react";
import styles from "./setting.module.scss";
import { url } from "config.js";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DxForm, { GroupItem, SimpleItem, Label, RequiredRule, NumericRule } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { dict, notify } from "helper/global";

function Component() {
  //
  // hooks

  const [state, setState] = useState(null);

  useEffect(() => {
    getScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // functions

  function getScenario() {
    (async () => {
      let result = await request({ url: `${url}/scenario` });
      setState(result);
    })();
  }

  // event handlers

  async function onSave() {
    try {
      await request({ url: `${url}/scenario`, method: "Put", data: state });
      notify(dict("Scenario updated successfully"), "success");
    } catch (error) {
      console.log("error");
    }
  }

  // render

  function buttonRender() {
    return (
      <div>
        <Button text={dict("Save")} type={"success"} stylingMode="outlined" width={100} onClick={onSave} />
      </div>
    );
  }

  function formRender() {
    return (
      <div className={styles.main}>
        <form>
          <DxForm
            formData={state || {}}
            labelLocation={"top"}
            minColWidth={250}
            width={1000}
            showColonAfterLabel={true}
            showRequiredMark={false}
          >
            <GroupItem caption="">
              <GroupItem colCount={4}></GroupItem>
            </GroupItem>
            <GroupItem colCount={4}>
              <GroupItem caption={dict("Calculation")}>
                <SimpleItem dataField="expiry">
                  <Label text={dict("Calculation Expiry (days)")} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption={dict("CoF")}>
                <SimpleItem dataField="minCof">
                  <Label text="Min CoF" />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="maxCof">
                  <Label text="Max CoF" />
                  <RequiredRule message="Required field" />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
            <SimpleItem render={buttonRender} />
          </DxForm>
        </form>
      </div>
    );
  }

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  return state ? formRender() : loadingRender();
}

export default Component;

import React from "react";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { withMemo } from "helper/global";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";

function Component(props) {
  //
  // stores

  const measurementStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.id) return Promise.resolve({ data: [] });
        return request({ url: `${url}/measurementlog/assetlog/${props.id}`, loadOptions: loadOptions });
      },
    }),
  };

  // render

  return (
    <Grid
      title={"Measurement"}
      showTitle={false}
      dataSource={measurementStore}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowExpanding={true}
      allowDownloading={false}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={200} />
      <Column caption={"Unit"} dataField={"unit"} allowFiltering={false} allowSorting={false} width={200} />
      <Column caption={"Value"} dataField={"value"} dataType={"number"} width={200} />
    </Grid>
  );
}

export default withMemo(Component, ["id"]);
